<template>
  <div class="project-comparison">
    <div class="w-crumbs-href">
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/work' }"
      >招商规划管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <span>{{$route.meta.title}}</span>
    </div>
    <div class="comparison-list">
      <div
        class="item"
        v-for="item in projectList"
        :key="item.projectId"
      >
        <div class="project-img">
          <img
            :src="item.projectLogo ? item.projectLogo + '?w=222&h=166' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
            alt=""
          >
        </div>
        <div class="project-name">{{item.projectName}}</div>
      </div>
      <div
        class="item seat-item"
        v-if="projectList && projectList.length < 2"
      >
        <div class="seat-info"></div>
        <div class="project-name">待添加对比项目</div>
      </div>
      <div
        class="item seat-item"
        v-if="projectList && projectList.length < 3"
      >
        <div class="seat-info"></div>
        <div class="project-name">待添加对比项目</div>
      </div>
      <!-- <div class="item">
        <div class="project-img"><img
            src="http://img3.winshang.com/Upload/project/2017/2/17/131317752759967389.png"
            alt=""
          ></div>
        <div class="project-name">南京金鹰中心</div>
      </div> -->
    </div>
    <div class="select-project">
      <span class="title">智能匹配逻辑：</span>
      系统将根据当前项目的项目类型、开业状态、开业时长、商业面积、项目档次、所属商圈类别，智能推荐与之近似的项目。您将看到处于同个行政区的项目，进行项目对比，将消耗企业的项目查看权益（已对比过的项目不会重复扣除）
    </div>
    <div class="recommend-wrap">
      <h3 class="w-title-gradients">推荐比对项目</h3>
      <div class="project-wrap">
        <ul>
          <li
            v-for="item in recommendList"
            :key="item.projectId"
          >
            <div class="project-info">
              <img
                :src="item.projectLogo ? item.projectLogo + '?w=109&h=82' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
              <div class="info">
                <div class="name">{{item.projectName}}</div>
                <div class="address">
                  <span>{{item.provinceName}}/{{item.cityName}}</span> <span>{{item.shangquanType}}/{{item.projectGrade}}</span>
                </div>
                <div class="status">
                  <span>开业{{item.kaiyeshichang}}年/</span><span>{{item.shangyeMianji}}㎡</span>
                </div>
                <div
                  class="btn"
                  @click="isCompartClick(item)"
                >
                  <i
                    :class="[item.isCompart === 0 ? 'icon-add-project' :'icon-delete-project']"
                    class="icon-font"
                  ></i> {{item.isCompart === 0 ? '加入对比栏' : '移出对比栏'}}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="no-data"
        v-if="recommendList && recommendList.length === 0"
      >
        <img
          src="@/assets/images/no_list_data.png"
          alt=""
        >
        <p>抱歉，当前模块暂无数据</p>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="lately-wrap">
      <h3 class="w-title-gradients">最近比对项目</h3>
      <div class="project-wrap">
        <ul>
          <li
            v-for="(item, index) in recnetProjectList"
            :key="index"
          >
            <div class="project-info">
              <img
                :src="item.projectLogo ? item.projectLogo + '?w=109&h=82' : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
              <div class="info">
                <div class="name">{{item.projectName}}</div>
                <div class="address">
                  <span>{{item.provinceName}}/{{item.cityName}}</span> <span>{{item.shangquanType}}/{{item.projectGrade}}</span>
                </div>
                <div class="status">
                  <span>已开业{{item.kaiyeshichang}}年/</span><span>{{item.shangyeMianji}}㎡</span>
                </div>
                <div
                  class="btn"
                  @click="isCompartClick(item)"
                >
                  <i
                    :class="[item.isCompart === 0 ? 'icon-add-project' :'icon-delete-project']"
                    class="icon-font "
                  ></i> {{item.isCompart === 0 ? '加入对比栏' : '移出对比栏'}}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="no-data"
        v-if="recnetProjectList && recnetProjectList.length === 0"
      >
        <img
          src="@/assets/images/no_list_data.png"
          alt=""
        >
        <p>抱歉，当前模块暂无数据</p>
      </div>
      <div class="clearfix"></div>
    </div>
    <!-- @isCompartClick="isCompartClick"
      @addCompartClick="addCompartClick" -->
    <comparison-tab
      v-if="projectList && projectList.length > 0"
      @reloadCompartClick="reloadCompartClick"
      :projectId="projectId"
      :decisionId="decisionId"
      ref="childMethod"
    ></comparison-tab>
  </div>
</template>

<script>
import api from '@/api'
import comparisonTab from '@/views/workBench/competitionComparison/components/comparisonTab.vue'
export default {
  name: 'projectComparison',
  data () {
    return {
      projectList: [],
      recommendList: [],
      recnetProjectList: []
    }
  },
  components: {
    comparisonTab
  },
  props: {
    projectId: {
      type: String
    },
    decisionId: {
      type: String
    }
  },
  mounted () {
    // console.log('projectId2', this.projectId)
    this.init()
  },
  methods: {
    init () {
      const data = {
        projectId: this.projectId
      }
      this.axios.post(api.competitionRecommend, data)
        .then((res) => {
          this.recommendList = res.data.data
        })
      this.axios.post(api.getTabComprojectListAtCompetition, data)
        .then((res) => {
          this.projectList = res.data.data
          // console.log(this.projectList)
        })
      this.axios.post(api.getRecentProjectListAtCompetition, data)
        .then((res) => {
          this.recnetProjectList = res.data.data
        })
    },
    reloadCompartClick () {
      this.init()
    },
    addCompartClick (item) {
      this.init()
    },
    isCompartClick (item) {
      const data = {
        projectId: this.projectId,
        comProjectId: item.projectId
      }
      if (item.isCompart === 0) {
        this.axios.post(api.addCompartAtCompetition, data)
          .then((res) => {
            if (res.data.code === 0) {
              this.init()
              this.$refs.childMethod.init()
            }
          })
      } else {
        this.axios.post(api.deleteCompartAtCompetition, data)
          .then((res) => {
            this.init()
            this.$refs.childMethod.init()
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.project-comparison
  margin 0 24px
  padding-bottom 80px
  .w-crumbs-href
    margin 12px 24px
    .link-item
      color #808191
    i
      color #808191
    span
      color #fff
  .comparison-list
    padding-top 24px
    height 202px
    background-color #272930
    border-radius 2px
    display flex
    .item
      flex 1
      text-align center
      .project-img
        width 160px
        height 120px
        margin auto
        img
          width 160px
          height 120px
          object-fit cover
          border-radius 2px
      .project-name
        font-weight 400
        font-size 16px
        color #fff
        margin-top 12px
  .select-project
    display flex
    align-items center
    margin-top 14px
    padding-left 16px
    background-color #272930
    border-radius 2px
    height 69px
    color #808191
    font-size 14px
    word-break break-word
    .title
      font-size 16px
      font-weight bold
      color #fff
  .recommend-wrap, .lately-wrap
    min-height 200px
    margin-top 14px
    padding-bottom 10px
    background-color #272930
    border-radius 2px
    .project-wrap
      padding 0 24px
      ul
        display flex
        flex-wrap wrap
        li
          margin-top 24px
          width 20%
          min-height 172px
          .project-info
            display flex
            .name
              font-size 16px
              font-weight bold
              color #FFF
            img
              width 109px
              height 82px
              border-radius 2px
              object-fit cover
            .info
              max-width 180px
              margin-left 12px
              .status1
                margin-top 14px
                span
                  font-size 14px
                  font-weight 400
                  color #B2B3BD
                  i
                    color #FFCE73
              .address
                margin-top 12px
                span
                  font-size 14px
                  font-weight 400
                  color #808191
              .status
                margin-top 6px
                span
                  font-size 14px
                  font-weight 400
                  color #808191
              .btn
                cursor pointer
                margin-top 19px
                background-color #1E1E23
                width 130px
                height 34px
                line-height 34px
                text-align center
                font-size 14px
                color #fff
                border-radius 2px
                &:hover
                  background-color #F89407
          .label
            margin-top 12px
            span
              background-color #3D3F45
              color #FFFFFF
              font-size 12px
              display inline-block
              padding 3px 8px
              border-radius 13px
.seat-item
  .seat-info
    width 160px
    height 120px
    border 1px solid #424752
    background-color #373A43
    border-radius 2px
    margin auto
  .project-name
    color #B2B3BD !important
    font-weight 400
    font-size 16px
    margin auto
@media screen and (max-width 1367px)
  .project-wrap ul li
    width 25% !important
</style>
